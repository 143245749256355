<template>
  <div :class="collapse ? 'position-collapse-left' : 'position-left'" class="headBar"
       style="border-bottom:1px solid #E4E7ED;" v-show="!isFullscreen">

    <!-- 导航收缩 -->
    <div class="hamburg-box" @click="onCollapse">
      <hamburger class="hamburg" :isActive="collapse"/>
    </div>


    <div class="tools">
      <!-- 全屏 -->
      <screenfull id="screenfull" />


      <!-- 用户信息 -->
      <el-popover ref="popover-personal" :visible-arrow="false" placement="bottom" trigger="hover">
        <span slot="reference" class="user-info" style="margin: 0 20px;">
              <img :alt="user.name" :src="user.avatar"/>
              {{ user.name }}
            </span>
        <personal-panel :user="user"></personal-panel>
      </el-popover>

      <!--机构切换-->
      <org-panel :orgs="orgs" @transfer = "getCurrentOrg"></org-panel>

      <!--主题切换-->
      <el-dropdown class="themes" type="primary" @command="onThemeChange">
        <img :alt="user.nickName" :src="setIcon" style="width: 24px;height: 24px;margin:6px"/>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="dark" :style="{color:theme==='dark'?'#00b6ed':'#606266'}">深色系</el-dropdown-item>
          <el-dropdown-item command="light" :style="{color:theme==='light'?'#00b6ed':'#606266'}">浅色系</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <!--tab切换栏-->
    <el-tabs v-model="mainTabsActiveName" class="tabs" @tab-click="selectedTabHandle"
             @tab-remove="removeTabHandle">
      <!--TODO: 2023.04.13. stone hard code. 待办不允许关闭.-->
      <el-tab-pane v-for="item in mainTabs" :key="item.name" :label="item.title" :name="item.name" :closable="item.title !== '待办'">
        <span slot="label"><i :class="item.icon"></i> {{ item.title }} </span>
      </el-tab-pane>
    </el-tabs>

  </div>
</template>

<script>
import {mapState} from 'vuex'
import mock from "@/mock/index"
import Hamburger from "@/components/Hamburger"
import ThemeSelector from "@/components/ThemeSelector"
import NoticePanel from "@/views/Core/NoticePanel"
import MessagePanel from "@/views/Core/MessagePanel"
import OrgPanel from "@/views/Core/OrgPanel"
import PersonalPanel from "@/views/Core/PersonalPanel"
import Screenfull from '@/components/Screenfull'
import Cookies from 'js-cookie'

export default {
  components: {
    Hamburger,
    ThemeSelector,
    NoticePanel,
    MessagePanel,
    OrgPanel,
    PersonalPanel,
    Screenfull
  },
  data()
  {
    return {
      user: {},
      currentOrg: {},
      orgs: [],
      setIcon: "",
      theme: ''
    }
  },
  methods: {
    selectNavBar(key, keyPath) {
      console.log(key, keyPath)
    },
    // 折叠导航栏
    onCollapse: function ()
    {
      this.$store.commit('onCollapse')
    },
    // 切换主题
    onThemeChange: function (theme)
    {
      this.theme = theme;
      Cookies.set('data-theme', theme);
      window.document.documentElement.setAttribute("data-theme", theme);
    },

    //获取当前机构
    getCurrentOrg(cOrg)
    {
      this.currentOrg = cOrg
      this.$emit('headbartransfer')
    },

    // tabs, 选中tab
    selectedTabHandle(tab)
    {
      tab = this.mainTabs.filter(item => item.name === tab.name)
      if (tab.length >= 1)
      {
        this.$router.push({name: tab[0].name})
      }
    },
    // tabs, 删除tab
    removeTabHandle(tabName)
    {
      this.mainTabs = this.mainTabs.filter(item => item.name !== tabName)
      if (this.mainTabs.length >= 1)
      {
        // 当前选中tab被删除
        if (tabName === this.mainTabsActiveName)
        {
          this.$router.push({name: this.mainTabs[this.mainTabs.length - 1].name}, () =>
          {
            this.mainTabsActiveName = this.$route.name
          })
        }
      }
      else
      {
        this.$router.push("/")
      }
    },
    // tabs, 关闭当前
    tabsCloseCurrentHandle()
    {
      this.removeTabHandle(this.mainTabsActiveName)
    },
    // tabs, 关闭其它
    tabsCloseOtherHandle()
    {
      this.mainTabs = this.mainTabs.filter(item => item.name === this.mainTabsActiveName)
    },
    // tabs, 关闭全部
    tabsCloseAllHandle()
    {
      this.mainTabs = []
      this.$router.push("/")
    },
    // tabs, 刷新当前
    tabsRefreshCurrentHandle()
    {
      var tempTabName = this.mainTabsActiveName
      this.removeTabHandle(tempTabName)
      this.$nextTick(() =>
      {
        this.$router.push({name: tempTabName})
      })
    }
  },
  async mounted()
  {
    this.theme = Cookies.get('data-theme');
    if (!this.theme)
    {
      this.theme = "light";
    }
    window.document.documentElement.setAttribute("data-theme", this.theme);
    this.setIcon = require("@/assets/set.png");

    var user = sessionStorage.getItem("user")

    //载入用户信息，不仅仅从session中，主要从后台接口取.
    if (user)
    {
      let params = {name: user}
      this.$api.user.findByName(params).then((res) =>
      {
        if (res.code === 200)
        {
          this.user = res.data
          this.user.avatar = require("@/assets/user.png")
        }
      });

      //载入用户可访问的机构信息，来自后台接口.传给子组件.
      //也可以在子组件中获取，此处由父组件获取，传给子组件.
      if (user) {
        let params = {name:user}
        this.$api.user.findByUserName(params).then((res) => {
          if(res.code === 200) {
            this.orgs = res.data
          }
        })
      }
    }

  },
  computed: {
    ...mapState({
      theme: state => state.app.theme,
      isFullscreen: state => state.app.isFullscreen,
      collapse: state => state.app.collapse
    }),
    mainTabs: {
      get()
      {
        return this.$store.state.tab.mainTabs
      },
      set(val)
      {
        this.$store.commit('updateMainTabs', val)
      }
    },
    mainTabsActiveName: {
      get()
      {
        return this.$store.state.tab.mainTabsActiveName
      },
      set(val)
      {
        this.$store.commit('updateMainTabsActiveName', val)
      }
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
