import Vue from 'vue'
import Router from 'vue-router'
import Loginindex from '@/views/Loginindex'
import NotFound from '@/views/404'
import Home from '@/views/Home'
import Blank from '@/views/Blank'
import Intro from '@/views/Intro/Intro'
import Workspace from '@/views/Workspace/Workspace'
import api from '@/http/api'
import store from '@/store'
import {getIFramePath, getIFrameUrl} from '@/utils/iframe'
import screenfull from "screenfull";
import {miniprogramwechatgettoken} from "../http/modules/login";
import Cookies from "js-cookie";

Vue.use(Router)

const router = new Router({
  mode:'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      redirect: "workspace/workspace",
      children: [
        {
          path: 'workspace/workspace',
          name: '待办',
          component: Workspace,
          meta: {
            icon: 'fa fa-home fa-lg',
            index: 0
          }
        }
      ]
    },
    {
      path: '/loginindex',
      name: '登录',
      component: Loginindex
    },
    {
      path: '/404',
      name: 'notFound',
      component: NotFound
    },
    {
      path: '/blank',
      name: 'blank',
      component: Home,
      redirect: "blank",
      children: [
        {
          path: 'blank',
          name: '空白',
          component: Blank,
          meta: {
            icon: 'fa fa-home fa-lg',
            index: 0
          }
        }
      ]
    }
  ]
})

// 全局前置守卫
router.beforeEach((to, from, next) => {
  console.log('start router.beforeEach.')
  if (to.query.isFullscreen) {
    if (to.query.isFullscreen === "true") {
      // 改变全屏状态.
      sessionStorage.setItem("isFullscreen", true);
    } else {
      sessionStorage.removeItem("isFullscreen");
    }
  } else {
    if (sessionStorage.getItem("isFullscreen")) {
      // do nothing.如果有了，就不要删除了.
    }
    else {
      sessionStorage.removeItem("isFullscreen");
    }
  }


  // 登录界面登录成功之后，会把用户信息保存在会话
  // 存在时间为会话生命周期，页面关闭即失效。
  let userName = sessionStorage.getItem('user')

  // 此处是为了给小程序调用webview使用的.
  // 如果已经传入userId了，那么就要去获取token,userid和username
  if (to.query.userId) {
    // 从后端获取username,userid和token.

    let params = {userId: to.query.userId};
    api.login.miniprogramwechatgettoken(params)
      .then(res => {
        console.log("miniprogramwechatgettoken retUserName is :" + res.data.retUserName)
        sessionStorage.setItem("user", res.data.retUserName);
        Cookies.set('token', res.data.retToken) // 放置token到Cookie
        userName = sessionStorage.getItem('user')

        if (to.path === '/loginindex') {
          // 如果是访问登录界面，如果用户会话信息存在，代表已登录过，跳转到主页
          if (userName) {
            next({path: '/'})
          } else {
            // 允许通过
            next()
          }
        } else {
          if (!userName) {
            // 如果访问非登录界面，且户会话信息不存在，代表未登录，则跳转到登录界面
            next({path: '/loginindex'})
          } else {
            // 加载动态菜单和路由
            addDynamicMenuAndRoutes(userName, to, from)
            // 允许通过.
            next({path: to.path})
            //next() 这个地方就是一天的修改，否则第一次就会一直停留在loginindex页面. 要指定路径，不能直接通过.
          }
        }

        console.log('end router.beforeEach.')
      })
      .catch(function (res) {
        console.error("miniprogramwechatgettoken error user is : " + res.data.retUserId)
      })

  } else {
    if (to.path === '/loginindex') {
      // 如果是访问登录界面，如果用户会话信息存在，代表已登录过，跳转到主页
      if (userName) {
        next({path: '/'})
      } else {
        // 允许通过.
        next()
      }
    } else {
      if (!userName) {
        // 如果访问非登录界面，且户会话信息不存在，代表未登录，则跳转到登录界面
        next({path: '/loginindex'})
      } else {
        // 加载动态菜单和路由
        addDynamicMenuAndRoutes(userName, to, from)
        // 允许通过. 一定更要这样，否则会重复跳转，死循环，但是为啥这里会多跳转几次那？而且不同页面跳转的次数不同........首页workspace正常，其它页面要多进入此处4次...........
        next()
      }
    }

    console.log('end router.beforeEach.')
  }

  console.log('jump router.beforeEach.')

})

/**
 * 加载动态菜单和路由
 */
function addDynamicMenuAndRoutes(userName, to, from)
{
  // 处理IFrame嵌套页面
  handleIFrameUrl(to.path)
  if (store.state.app.menuRouteLoaded)
  {
    // console.log('动态菜单和路由已经存在.')
    return
  }
  api.menu.findNavTree({'userName': userName})
    .then(res =>
    {
      // console.log("动态菜单：" + res.data)
      // 添加动态路由
      let dynamicRoutes = addDynamicRoutes(res.data)
      // 处理静态组件绑定路由
      router.options.routes[0].children = router.options.routes[0].children.concat(dynamicRoutes)
      router.addRoutes(router.options.routes)
      // 保存加载状态
      store.commit('menuRouteLoaded', true)
      // 保存菜单树
      store.commit('setNavTree', res.data)
    }).then(res =>
  {
    api.user.findPermissions({'name': userName}).then(res =>
    {
      // 保存用户权限标识集合
      store.commit('setPerms', res.data)
    })
  })
    .catch(function (res)
    {
    })
}

/**
 * 处理IFrame嵌套页面
 */
function handleIFrameUrl(path)
{
  // 嵌套页面，保存iframeUrl到store，供IFrame组件读取展示
  let url = path
  let length = store.state.iframe.iframeUrls.length
  for (let i = 0; i < length; i++)
  {
    let iframe = store.state.iframe.iframeUrls[i]
    if (path != null && path.endsWith(iframe.path))
    {
      url = iframe.url
      store.commit('setIFrameUrl', url)
      break
    }
  }
}

/**
 * 添加动态(菜单)路由
 * @param {*} menuList 菜单列表
 * @param {*} routes 递归创建的动态(菜单)路由
 */
function addDynamicRoutes(menuList = [], routes = [])
{
  var temp = []
  for (var i = 0; i < menuList.length; i++)
  {
    if (menuList[i].children && menuList[i].children.length >= 1)
    {
      temp = temp.concat(menuList[i].children)
    }
    else if (menuList[i].url && /\S/.test(menuList[i].url))
    {
      menuList[i].url = menuList[i].url.replace(/^\//, '')
      // 创建路由配置
      var route = {
        path: menuList[i].url,
        component: null,
        name: menuList[i].name,
        meta: {
          icon: menuList[i].icon,
          index: menuList[i].id,
          keepAlive: menuList[i].keepAlive === 1
        }
      }
      let path = getIFramePath(menuList[i].url)
      if (path)
      {
        // 如果是嵌套页面, 通过iframe展示
        route['path'] = path
        route['component'] = resolve => require([`@/views/IFrame/IFrame`], resolve)
        // 存储嵌套页面路由路径和访问URL
        let url = getIFrameUrl(menuList[i].url)
        let iFrameUrl = {'path': path, 'url': url}
        store.commit('addIFrameUrl', iFrameUrl)
      }
      else
      {
        try
        {
          // 根据菜单URL动态加载vue组件，这里要求vue组件须按照url路径存储
          // 如url="sys/user"，则组件路径应是"@/views/sys/user.vue",否则组件加载不到
          let array = menuList[i].url.split('/')
          let url = ''
          for (let i = 0; i < array.length; i++)
          {
            url += array[i].substring(0, 1).toUpperCase() + array[i].substring(1) + '/'
          }
          url = url.substring(0, url.length - 1)
          route['component'] = resolve => require([`@/views/${url}`], resolve)
        }
        catch (e)
        {
        }
      }
      routes.push(route)
    }
  }
  if (temp.length >= 1)
  {
    addDynamicRoutes(temp, routes)
  }
  else
  {
    // console.log('动态路由加载...')
    // console.log(routes)
    // console.log('动态路由加载完成.')
  }
  return routes
}

export default router
