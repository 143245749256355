var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.isFullscreen,
          expression: "!isFullscreen"
        }
      ],
      staticClass: "menu-bar-container"
    },
    [
      _c(
        "div",
        {
          staticClass: "logo",
          class: _vm.collapse ? "menu-bar-collapse-width" : "menu-bar-width",
          on: {
            click: function($event) {
              return _vm.$router.push("/")
            }
          }
        },
        [
          _c("img", { attrs: { src: require("@/assets/logo.png") } }),
          _vm._v(" "),
          _c("div", [_vm._v(_vm._s(_vm.collapse ? "" : _vm.appName))])
        ]
      ),
      _vm._v(" "),
      _c(
        "el-menu",
        {
          ref: "navmenu",
          class: _vm.collapse ? "menu-bar-collapse-width" : "menu-bar-width",
          attrs: {
            collapse: _vm.collapse,
            "collapse-transition": false,
            "unique-opened": true,
            "active-text-color": "#FFFFFF",
            "default-active": "1"
          },
          on: {
            close: _vm.handleclose,
            open: _vm.handleopen,
            select: _vm.handleselect
          }
        },
        _vm._l(_vm.navTree, function(item) {
          return _c("menu-tree", { key: item.id, attrs: { menu: item } })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-641e9e78", { render: render, staticRenderFns: staticRenderFns })
  }
}