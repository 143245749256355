var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.isFullscreen,
          expression: "!isFullscreen"
        }
      ],
      staticClass: "headBar",
      class: _vm.collapse ? "position-collapse-left" : "position-left",
      staticStyle: { "border-bottom": "1px solid #E4E7ED" }
    },
    [
      _c(
        "div",
        { staticClass: "hamburg-box", on: { click: _vm.onCollapse } },
        [
          _c("hamburger", {
            staticClass: "hamburg",
            attrs: { isActive: _vm.collapse }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tools" },
        [
          _c("screenfull", { attrs: { id: "screenfull" } }),
          _vm._v(" "),
          _c(
            "el-popover",
            {
              ref: "popover-personal",
              attrs: {
                "visible-arrow": false,
                placement: "bottom",
                trigger: "hover"
              }
            },
            [
              _c(
                "span",
                {
                  staticClass: "user-info",
                  staticStyle: { margin: "0 20px" },
                  attrs: { slot: "reference" },
                  slot: "reference"
                },
                [
                  _c("img", {
                    attrs: { alt: _vm.user.name, src: _vm.user.avatar }
                  }),
                  _vm._v(
                    "\n            " + _vm._s(_vm.user.name) + "\n          "
                  )
                ]
              ),
              _vm._v(" "),
              _c("personal-panel", { attrs: { user: _vm.user } })
            ],
            1
          ),
          _vm._v(" "),
          _c("org-panel", {
            attrs: { orgs: _vm.orgs },
            on: { transfer: _vm.getCurrentOrg }
          }),
          _vm._v(" "),
          _c(
            "el-dropdown",
            {
              staticClass: "themes",
              attrs: { type: "primary" },
              on: { command: _vm.onThemeChange }
            },
            [
              _c("img", {
                staticStyle: { width: "24px", height: "24px", margin: "6px" },
                attrs: { alt: _vm.user.nickName, src: _vm.setIcon }
              }),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "el-dropdown-item",
                    {
                      style: {
                        color: _vm.theme === "dark" ? "#00b6ed" : "#606266"
                      },
                      attrs: { command: "dark" }
                    },
                    [_vm._v("深色系")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-item",
                    {
                      style: {
                        color: _vm.theme === "light" ? "#00b6ed" : "#606266"
                      },
                      attrs: { command: "light" }
                    },
                    [_vm._v("浅色系")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-tabs",
        {
          staticClass: "tabs",
          on: {
            "tab-click": _vm.selectedTabHandle,
            "tab-remove": _vm.removeTabHandle
          },
          model: {
            value: _vm.mainTabsActiveName,
            callback: function($$v) {
              _vm.mainTabsActiveName = $$v
            },
            expression: "mainTabsActiveName"
          }
        },
        _vm._l(_vm.mainTabs, function(item) {
          return _c(
            "el-tab-pane",
            {
              key: item.name,
              attrs: {
                label: item.title,
                name: item.name,
                closable: item.title !== "待办"
              }
            },
            [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                _c("i", { class: item.icon }),
                _vm._v(" " + _vm._s(item.title) + " ")
              ])
            ]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-64f3d06f", { render: render, staticRenderFns: staticRenderFns })
  }
}